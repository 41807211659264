import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import type { MetaDescriptor } from "@remix-run/node";
import { match } from "ts-pattern";

import { BIG_PICTURE_ORIGIN, SANITY_CDN_BASE_URL } from "~/constants";
import { makeSanityImageSrcSet } from "~/utils/sanityImage";

import ogFallbackCa from "../assets/img_og_fallback_ca.png";
import ogFallbackJp from "../assets/img_og_fallback_jp.png";
import ogFallbackKr from "../assets/img_og_fallback_kr.png";
import ogFallbackUk from "../assets/img_og_fallback_uk.png";
import ogFallbackUs from "../assets/img_og_fallback_us.png";

const fallbackOgImage: {
  [country in KarrotLocalCountryCode]: string;
} = {
  ca: ogFallbackCa,
  jp: ogFallbackJp,
  us: ogFallbackUs,
  uk: ogFallbackUk,
  kr: ogFallbackKr,
};

function makeImageMetaUrl(
  url: URL,
  options: {
    width: string;
    height: string;
    quality: number;
    noResize: boolean;
  },
) {
  return (
    match(url.toString())
      .when(
        () => options.noResize,
        () => url.toString(),
      )
      .when(
        (u) =>
          u.startsWith(BIG_PICTURE_ORIGIN) || u.includes("gcp-karroter.net"),
        () => {
          url.searchParams.set("s", `${options.width}x${options.height}`);
          url.searchParams.set("t", "cover");
          url.searchParams.set("q", options.quality.toString());

          return url.toString();
        },
      )
      .when(
        (u) => u.startsWith(SANITY_CDN_BASE_URL),
        () => {
          const sanityUrl = makeSanityImageSrcSet(url.toString(), "large", {
            w: options.width,
            h: options.height,
            q: options.quality.toString(),
            useCloudFrontProxy: true,
          });

          return sanityUrl;
        },
      )
      // 기타 cdn을 사용하는 imageUrl의 경우 그대로 반환합니다.
      .otherwise((u) => u)
  );
}

const OG_IMAGE_WIDTH = "1200";
const OG_IMAGE_HEIGHT = "630";

export function imageMeta(
  countryCode = KarrotLocalCountryCode.CA,
  imageUrl?: string | null,
  options?:
    | {
        width?: string;
        height?: string;
        quality?: number;
        noResize?: false;
      }
    | {
        width: string;
        height: string;
        quality?: number;
        noResize: true;
      },
): MetaDescriptor[] {
  if (typeof imageUrl !== "string") {
    return [
      { property: "og:image", content: fallbackOgImage[countryCode] },
      { name: "twitter:image", content: fallbackOgImage[countryCode] },
      { property: "og:image:width", content: "1200" },
      { property: "og:image:height", content: "630" },
    ];
  }

  const width = options?.width ?? OG_IMAGE_WIDTH;
  const height = options?.height ?? OG_IMAGE_HEIGHT;

  try {
    const url = new URL(imageUrl);
    const imageMetaUrlOptions = {
      width,
      height,
      quality: options?.quality ?? 95,
      noResize: options?.noResize ?? false,
    };
    const content = makeImageMetaUrl(url, imageMetaUrlOptions);

    return [
      { property: "og:image", content },
      { name: "twitter:image", content },
      { property: "og:image:width", content: width },
      { property: "og:image:height", content: height },
    ];
  } catch {
    return [];
  }
}
